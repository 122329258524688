import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NotificationService } from "../notification/notification.service";
import { UserService } from "@kortobaa-front/authentication";
import { PlatformService } from "src/app/core/services/platform.service";
import { checkLanguagePipe } from "src/app/shared/pipes/checkLanguage.pipe";
import { NotifictionNumberPipe } from "src/app/shared/pipes/notifiction-number.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { AuthService } from "src/app/auth/services/auth.service";
import { LanguageIconComponent } from "../../../shared/components/language-icon/language-icon.component";
import { ProfileService } from "src/app/shared/services/profile.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  standalone: true,
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    RouterLink,
    checkLanguagePipe,
    NotifictionNumberPipe,
    MatMenuModule,
    LanguageIconComponent,
  ],
})
export class HeaderComponent {
  @Input() route: any = [];
  @Input() sideNavToggleBtn: boolean = false;
  navFlag: string = "close";
  hasUnseenNotification!: number;
  @Output() sideNavToggleBtnRes = new EventEmitter<boolean>();
  userLogin = !!this.auth.user.currentUser && !!localStorage.getItem("Token") && !!localStorage.getItem("UserProfile");
  notifySub$!: Subscription;

  constructor(
    private router: Router,
    private _notification: NotificationService,
    public user: UserService,
    public _platForm: PlatformService,
    public _translate: TranslateService,
    public auth: AuthService,
    private _ProfileService: ProfileService
  ) {
    this._notification.emitCloseDialog(this.navFlag);
    this._notification.getCloseDialog().subscribe((data) => {
      this.navFlag = data;
    });
  }

  async ngOnInit() {
    (await this.user.checkUserValid()).subscribe((data: any) => {
      if (data) {
        this._notification.getNotificationsCount().subscribe((data: any) => {
          this.hasUnseenNotification = data.count;
        });
      }
    });
    this._notification.getUserIsAuthenticated().subscribe((data: any) => {
      this._notification.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
    this._notification.getSeenNotification().subscribe((data: any) => {
      this._notification.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });

    // Check notifications when tab (or browser window) regains focus
    let visibilityChangeHandler = () => {
      if (document.visibilityState === "visible") {
        this._notification.getNotificationsCount().subscribe((data: any) => {
          this.hasUnseenNotification = data.count;
        });
      }
    };
    document.addEventListener("visibilitychange", visibilityChangeHandler);
  }

  // redirect to
  redirectTo(route: string) {
    // if (route == 'consultant-data') {
    //   this._certifiedConsultant.emitEvent(false);
    //   // this.router.navigate([]);
    //   return;
    // }
    if (route == "ended-meetings" || route == "scheduled-meetings" || route == "active-meetings") {
      this.router.navigate(["meetings/" + route]);
      return;
    }
    if (route == "all" || route == "active-teachers" || route == "inactive-teachers") {
      this.router.navigate(["teachers/" + route]);
      return;
    }
    if (route == "meeting") {
      let routing: any = this.router.url.split("/");
      routing.pop();
      routing.pop();
      this.router.navigate([routing.join("/")]);
      return;
    }
    if (route == "student-info") {
      this.router.navigate([`students/${this._ProfileService.currentProfileId.value}/student-info`]);
      return;
    }
    if (route == "teacher-info") {
      this.router.navigate([`teachers/teacher-info/${this._ProfileService.currentProfileId.value}`]);
      return;
    }
    this.router.navigate([route]);
  }

  // open notification
  openNotification(value: any) {
    this.navFlag = this.navFlag == "close" ? "open" : "close";
    this._notification.emitCloseDialog(this.navFlag);
    if (this.navFlag == "open") {
      // this.notifySub$ = this._notification.updateNotificationToSeen().subscribe((data) => {
      //   // this.hasUnseenNotification = false;
      //   this._notification.emitSeenNotification();
      // });
      this._notification.emitOpenDialog();
    }
  }

  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
  }
}
